<template>
  <article
    v-click-outside="hide"
    class="os-card"
    :class="{
      'os-card--plain': type === ECardType.PLAIN,
      'os-card--shadow': type === ECardType.SHADOW,
      'os-card--border': type === ECardType.BORDER,
      'os-card--clickable': clickable,
      'os-card--remove-ctas-open': isRemoveCtasOpen,
      'os-card--removed': isRemoved
    }"
    :style="style"
  >
    <transition name="cardRemoveCtasAnimation">
      <div
        v-if="isRemoveCtasOpen"
        class="row os-card__remove-ctas m-0"
        @click.prevent.stop
      >
        <div class="col-6">
          <OsButton aria-label="No, Keep" @click.prevent="toggleActions"
            >No, keep</OsButton
          >
        </div>
        <div class="col-6">
          <OsButton
            :is-primary="true"
            aria-label="Yes, remove"
            @click.prevent="remove"
          >
            Yes, remove
          </OsButton>
        </div>
      </div>
    </transition>
    <slot name="image" />
    <OsCardTitle v-if="isShowTitle">
      <slot name="title">
        {{ title }}
      </slot>
      <template #actions>
        <slot name="actions" />
        <div v-if="isRemovable" class="os-card__remove-toggle">
          <button @click.stop="toggleActions">
            <OsIcon
              v-if="isShortList"
              name="Heart"
              size="1.5rem"
              class="text-blue-600"
            />
            <OsIcon v-else name="Delete" class="text-blue-600" />
          </button>
        </div>
      </template>
    </OsCardTitle>

    <slot />
  </article>
</template>

<script lang="ts">
import { ECardType } from '~/types/components/card'
import { useCss } from '~/composables/useCss'
import clickOutside from '~/directives/clickOutside'

export default {
  name: 'OsCard',

  directives: {
    clickOutside
  },
  props: {
    type: {
      type: String as PropType<ECardType>,
      required: false,
      default: ECardType.SHADOW
    },
    width: {
      type: [String, Number],
      required: false,
      default: ''
    },
    maxWidth: {
      type: [String, Number],
      required: false,
      default: ''
    },
    // TODO: Make isClickable
    clickable: {
      type: Boolean,
      required: false,
      default: false
    },
    isRemovable: {
      type: Boolean,
      required: false,
      default: false
    },
    isShortList: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['remove'],

  setup(props, { slots, emit }) {
    const { formatStyleSizeToPx } = useCss()

    const style = computed(() =>
      formatStyleSizeToPx({
        width: props.width,
        maxWidth: props.maxWidth
      })
    )

    const isShowTitle = computed(
      () => props.isRemovable || !!props.title || !!slots.title
    )

    const isRemoved = ref<boolean>(false)
    const isRemoveCtasOpen = ref<boolean>(false)

    const toggleActions = () => {
      isRemoveCtasOpen.value = !isRemoveCtasOpen.value
    }
    const hide = () => {
      isRemoveCtasOpen.value = false
    }
    const remove = () => {
      isRemoved.value = true

      emit('remove')
    }

    return {
      ECardType,
      style,
      isShowTitle,
      isRemoveCtasOpen,
      toggleActions,
      hide,
      isRemoved,
      remove
    }
  }
}
</script>

<style scoped lang="scss">
.os-card {
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.7s $cubicEasing;
  position: relative;

  &:deep(img) {
    border-radius: inherit;
  }

  &--shadow {
    box-shadow: $shadow;
  }

  &--plain {
    &:deep(img) {
      border-radius: 6px;
    }

    &:deep(.os-card__title),
    &:deep(.os-card__content) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--remove-ctas-open {
    pointer-events: none;

    &:deep(> *:not(.os-card__remove-ctas)) {
      opacity: 0.4;
    }
  }

  &--removed {
    max-height: 0;
    transform: scale(0.85);
    opacity: 0;
    pointer-events: none;
  }

  .os-card__remove-toggle {
    button {
      cursor: pointer;
    }
  }

  .os-card__remove-ctas {
    padding: 8px;
    overflow: hidden;

    &:deep(.os-button) {
      width: 100%;
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.45s $cubicEasing;
      pointer-events: auto;
    }
  }

  .cardRemoveCtasAnimation-enter-active,
  .cardRemoveCtasAnimation-leave-active {
    transition: all 0.45s $cubicEasing;
    max-height: 76px;
    opacity: 1;
  }

  .cardRemoveCtasAnimation-enter,
  .cardRemoveCtasAnimation-leave-to {
    max-height: 0px;
    overflow: hidden;
    opacity: 0;

    &:deep(.os-button) {
      transform: translateY(20px);
      opacity: 0;
    }
  }
  // end remove
}
</style>
