<template>
  <div class="os-card__title text-lg bold leading-5">
    <slot />
    <div class="grow" />
    <slot name="actions" />
  </div>
</template>

<style lang="scss" scoped>
.os-card__title {
  display: flex;
  align-items: flex-start;
  padding: rem(16) rem(24) 0;
  color: $black--light;
}
</style>
